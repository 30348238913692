import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { EntityType } from '../../models/entity-type.enum';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { GlobalEntitiesService } from './global-entities.service';
import { IAppState } from 'src/app/store/state/app.state';
import { Hierarchy, OrganizationHierarchyTree, EntityTreeNode, OrgWithDescendants } from '../../models/hierarchy.model';
import { map } from 'rxjs/operators';
import { OrganizationDetails } from '../../models/organizations.model';

interface OrganizationHierarchyNode {
  value: any;
  type: EntityType;
  nodes: OrganizationHierarchyNode[]
}

@Injectable({
  providedIn: 'root'
})
export class HierarchyService extends GlobalEntitiesService {
  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  fetchHierarchy(entityId: number, entityType: EntityType): Observable<Hierarchy> {
    return this.http.get<any[]>(`navigation/path/${entityType}/${entityId}`);
  }

  fetchOrganizationHierarchyTree(): Observable<OrganizationHierarchyTree> {
    return this.http.get<OrganizationHierarchyNode[]>(`organizations/tree`).pipe(
      map<OrganizationHierarchyNode[], OrganizationHierarchyTree>((nodes) => {
        let convertToHierarchyNode = (node: OrganizationHierarchyNode, children: EntityTreeNode[] = []): EntityTreeNode => {
          let category = node.value.type
          let entityNode = {
            children: children,
            value: {
              ...node.value,
              type: node.type
            }
          }
          // The tenant has a category which is getting from server as "type" inside value
          // Since we use type as the entity type we need to first copy the type into category
          // and then put it into the tenant entity.
          if (node.type == EntityType.TENANT) {
            entityNode.value["category"] = category
          }
          return entityNode
        }
        // simple recursive function that puts the "nodes" into "children" and copy the "type" into the value
        // (we need the last one since the "value" property is an Entity which needs a type field)
        let traverse = (nodes: OrganizationHierarchyNode[]): OrganizationHierarchyTree => {
          return nodes.map((n) => {
            if (!n) {
              return
            }
            if (n.nodes.length == 0) {
              return convertToHierarchyNode(n, [])
            }
            let children = traverse(n.nodes)
            return convertToHierarchyNode(n, children)
          }) as EntityTreeNode[];
        }
        return traverse(nodes)
      })
    );
  }

  fetchAllOrgDescendantsAsArray(orgId: number): Observable<OrgWithDescendants> {
    return this.fetchOrganizationHierarchyTree().pipe(
      map(tree => {
        return { currentOrg: this.findCurrentOrgDetails(orgId, tree[0]), descendants: this.findOrgDescendants(orgId, tree[0]) };
      }))
  }

  findCurrentOrgDetails(orgId: number, tree: EntityTreeNode): EntityTreeNode {
    let currentParent = tree.value.id == orgId ?
      tree :
      tree.children.find(child => child.value.id == orgId);
    if (currentParent)
      return currentParent;
    else {
      for (let index = 0; index < tree.children.length; index++) {
        const child = tree.children[index];
        if (child.value.id == orgId)
          return child;
        else
          return this.findCurrentOrgDetails(orgId, child);
      }
    }
  }

  findOrgDescendants(orgId: number, tree: EntityTreeNode): OrganizationDetails[] {
    let currentParent = tree.value.id == orgId ?
      tree :
      tree.children.find(child => child.value.id == orgId);
    if (currentParent) {
      return this.flattenChildren(currentParent.children);
    }
    else {
      for (let index = 0; index < tree.children.length; index++) {
        const child = tree.children[index];
        if (this.findOrgDescendants(orgId, child))
          return this.findOrgDescendants(orgId, child);
        else this.findOrgDescendants(orgId, child);
      }
    }
  }

  flattenChildren(children: EntityTreeNode[], childrenArray: OrganizationDetails[] = []): OrganizationDetails[] {
    children.forEach(child => {
      childrenArray.push(child.value as OrganizationDetails);
      if (child.children && child.children.length > 0)
        return this.flattenChildren(child.children, childrenArray)
    });
    return childrenArray;
  }
}
